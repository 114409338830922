import React, { useMemo, useState } from "react"
import { 
  Button,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material"
import { 
  DownloadIcon,
  HoverPaper,
  LabeledIconButton,
  LoadingButton,
  LoadingLinear,
  ScrollingList,
  Styled,
  useAppointmentLocations,
  useCalendarEventRSVPs, 
  useCalendarEvents, 
  useEnduserSession, 
  useEnduserTasks, 
  useFileForSecureName, 
  useFormResponses, 
  useManagedContentRecords, 
  useResolvedSession,
  useUsers,
  value_is_loaded,
} from "@tellescope/react-components"
import {
  CalendarEvent, EnduserTask,
} from "@tellescope/types-client"
import { dashboardChildStyles, DASHBOARD_CHILD_MARGIN } from "../definitions/constants"
import { routes, useNavigateToPage } from "../definitions/routes"
import { formatted_date, fullMonth_day_year, sorted_records, time_for_calendar_event, user_display_name } from "@tellescope/utilities"
import { CalendarIcon, CheckCircleIcon, FileDownloadIcon, TaskPendingIcon, WithCircularBackground } from "../definitions/icons"
import { useParams } from "react-router-dom"
import { cardListTitleStyle } from "../definitions/styles"
import { ContentPreview } from "./Content"
import { FormResponsePreview } from "./Documents"
import { GenericAttachment } from "@tellescope/types-models"
import { VirtualVisitIcon } from "../components/icons"
import { AppointmentBookingPage } from "./AppointmentBooking/AppointmentBooking"
import { ResponsiveModal } from "../components/layout"
import { usePolling } from "../hooks"

export interface WithEvent { event: CalendarEvent }

const AttachmentCard = ({ attachment }: { attachment: GenericAttachment }) => {
  const url = useFileForSecureName(attachment)

  return (
    <HoverPaper 
      onClick={() => window.open(url, "_blank")}
      style={{ 
        padding: 15, borderRadius: 15,
        margin: 2, marginBottom: 5 
      }}
    >
    <Grid container alignItems="center">
      <FileDownloadIcon color="primary" sx={{ mr: 1 }}  />
      {attachment.displayName}
    </Grid>
    </HoverPaper>
  )
}

export const EventFiles = ({ event } : WithEvent) => {
  if (!event.attachments?.length) return null

  return (
    <ScrollingList items={event.attachments.map(a => ({ ...a, id: a.fileId }))} maxHeight={'33vh'}
      titleStyle={cardListTitleStyle}
      title={'Attachments'}
      Item={({ item: attachment }) => <AttachmentCard attachment={attachment} />}
    />
  )
}

export const TaskCard = ({ item: task } : { item: EnduserTask }) => {
  const [, { updateElement: updateTask }] = useEnduserTasks({ dontFetch: true })
  const completed = !!task.completedAt
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item>
        {completed
          ? <CheckCircleIcon color="success" />
          : <LabeledIconButton Icon={TaskPendingIcon} label={'Mark Complete'} onClick={() => {
              if (completed) return
              updateTask(task.id, { completedAt: new Date() })
            }} />
        }
      </Grid>

      <Grid item direction="column" wrap="nowrap" sx={{ ml: 1 }}>
        <Typography noWrap color="primary" style={{ 
          fontWeight: 'bold', 
          fontSize: 20,
        }}>
          {task.title}
        </Typography>

        <Typography noWrap color="primary" style={{ 
          fontWeight: 'bold', 
          fontSize: 16,
        }}>
          {task.description}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const EventTasks = ({ event } : WithEvent) => {
  const [, { filtered, doneLoading, loadMore }] = useEnduserTasks()
  if (!event.enduserTasks?.length) return null

  return (
    <LoadingLinear data={filtered(task => !!event.enduserTasks?.find(t => t.id === task.id))} render={records => (
      <ScrollingList items={records} maxHeight={'33vh'} doneLoading={doneLoading} loadMore={loadMore}
        titleStyle={cardListTitleStyle}
        title={'Tasks'}
        Item={props => (
          <Paper elevation={3} style={{ 
            padding: 15, borderRadius: 15,
            margin: 2, marginBottom: 5 
          }}>
            <TaskCard {...props} />
          </Paper>
        )}
      />
    )} />
  )
}

export const EventFormResponses = ({ event } : WithEvent) => {
  const [, { filtered, doneLoading, loadMore }] = useFormResponses()
  if (!event.enduserFormResponses?.length) return null

  return (
    <LoadingLinear data={filtered(fr => !!event.enduserFormResponses?.find(_r => _r.accessCode === fr.accessCode))} render={records => (
      <ScrollingList items={records} maxHeight={'33vh'} doneLoading={doneLoading} loadMore={loadMore}
        titleStyle={cardListTitleStyle}
        title={'Forms'}
        Item={props => (
          <HoverPaper disabled={!!props.item.responses?.length} style={{ 
            padding: 15, borderRadius: 15,
            margin: 2, marginBottom: 5 
          }}>
            <FormResponsePreview {...props} />
          </HoverPaper>
        )}
      />
    )} />
  )
}

export const EventContent = ({ event } : WithEvent) => {
  const [, { filtered, doneLoading, loadMore }] = useManagedContentRecords()
  if (!event.sharedContentIds?.length) return null
  
  return (
    <LoadingLinear data={filtered(r => event.sharedContentIds!.includes(r.id))} render={records => (
      <ScrollingList items={records} maxHeight={'33vh'} doneLoading={doneLoading} loadMore={loadMore}
        titleStyle={cardListTitleStyle}
        title={'Content'}
        Item={ContentPreview}
      />
    )} />
  )
}

const LABEL_WIDTH = '33%'
const EventDetail = ({ label, value, isURL, height=40 } : { label: string, value: string, isURL?: boolean, height?: number }) => (
  <Grid container columnSpacing={2} sx={{ height }}>
    <Grid item style={{ width: LABEL_WIDTH }}>
      <Typography style={{ fontSize: 17, textAlign: 'right' }}>
      <strong>
        {label}
      </strong>
      </Typography>  
    </Grid>

    <Grid item style={{ height, overflowY: 'auto', width: `calc(100% - ${LABEL_WIDTH})`}}>
      <Typography style={{ fontSize: 17, opacity: 0.8 }}>
        {isURL 
          ? <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
          : value.split('\n').map((v, i) => (
            <div key={i}>
              {v}
            </div>
          ))
        }
      </Typography>  
    </Grid>
  </Grid>
)

const EventDetails = ({ event } : { event: CalendarEvent }) => {
  const [, { findById }] = useUsers()
  const [locationsLoading] = useAppointmentLocations({ dontFetch: !event.locationId })
  const userIds = Array.from(new Set(
    event.attendees.filter(e => e.type === 'user').map(u => u.id)
  ))

  const location = (
    value_is_loaded(locationsLoading)
      ? locationsLoading.value.find(l => l.id === event.locationId)
      : undefined
  )

  const hostId = event.attendees.find(a => a.type === 'user')?.id
  const host = (event.useUserURL && hostId) ? findById(hostId) : undefined

  return (
    <Grid container direction="column">
      {event.carePlanNote &&
        <Grid item>
          <EventDetail label="Care Plan Note" value={event.carePlanNote} 
            height={100}
          />
        </Grid>
      }

      <Grid item>
        <EventDetail label="Date" value={fullMonth_day_year(new Date(event.startTimeInMS))} />
      </Grid>

      <Grid item>
        <EventDetail label="Time" value={time_for_calendar_event(event)} />
      </Grid>

      {userIds.length > 0 && !event.portalSettings?.hideUsers &&
        <Grid item>
          <EventDetail 
            label={userIds.length === 1 ? "Provider" : "Providers"} 
            value={userIds.map(u => user_display_name(findById(u))).join(', ')} 
          />
        </Grid>
      }

      {location &&
        <Grid item>
          <EventDetail label={'Location'} value={location.title} />
        </Grid>

      }
      {location?.address &&
        <Grid item>
          <EventDetail label={'Address'} value={location.address} />
        </Grid>

      }
      {location?.phone &&
        <Grid item>
          <EventDetail label={'Phone'} value={location.phone} />
        </Grid>

      }
      {event.location && 
        <Grid item>
          <EventDetail label={'Location'} value={event.location} />
        </Grid>
      }
      {event.locationURL && 
        <Grid item>
          <EventDetail label={'URL'} value={event.locationURL} isURL />
        </Grid>
      }
      {event.useUserURL && host?.url &&
        <Grid item>
          <EventDetail label={'Host URL'} value={host.url} isURL />
        </Grid>
      }
      {event.phone && 
        <Grid item>
          <EventDetail label={'Phone'} value={event.phone} />
        </Grid>
      }
      {event.locationNotes && 
        <Grid item>
          <EventDetail label={'Notes'} value={event.locationNotes} />
        </Grid>
      }
      {event.description && 
        <Grid item>
          <EventDetail label={'Description'} value={event.description} />
        </Grid>
      }
    </Grid>
  )
}

export const Event = () => {
  const session = useEnduserSession()
  const navigate = useNavigateToPage()
  const [, { findById: findEvent, updateElement: updateEvent }] = useCalendarEvents()
  const id = useParams().id
  const event = findEvent(id!)
  const [showReschedule, setShowReschedule] = useState(false)
  const [confimingCancel, setConfirmingCancel] = useState(false)

  usePolling(() => {
    if (event?.videoURL) return
    if (!event?.videoIntegration || event.videoIntegration === 'No Integration') return 

    findEvent(event.id, { reload: true })
  }, 5000)

  if (event === undefined) return <LinearProgress />
  if (event === null) return (
    <Typography sx={{ padding: 1 }}>
      This event has been rescheduled or deleted
    </Typography>
  )

  const tasks = <EventTasks event={event}></EventTasks>
  const responses = <EventFormResponses event={event} />
  const content = <EventContent event={event} />
  const files = <EventFiles event={event} />

  if (showReschedule && event.bookingPageId) {
    return (
      <AppointmentBookingPage 
        userId={event.attendees.find(a => a.type === 'user')?.id}
        businessId={event.businessId}
        rescheduledCalendarEventId={event.id}
        appointmentBookingPageId={event.bookingPageId}
      />
    )
  }

  return (
    <>
    <ResponsiveModal open={confimingCancel} setOpen={setConfirmingCancel} style={{ maxWidth: 500, width: '90%' }}>
    <Grid container justifyContent={"center"} sx={{ padding: 4 }}>
    <Grid container direction="column" sx={{ maxWidth: 400 }}>
      <Typography sx={{ mb: 1, fontSize: 20 }}>
        Are you sure you want to cancel your appointment?
      </Typography>

      <LoadingButton variant="contained"
        disabled={!!(event.cancelledAt || event.rescheduledAt)}
        submitText="Yes, Cancel"
        style={{
          width: '100%',
          marginTop: 30,
        }}
        onClick={async () => {
          if (event.enduserAttendeeLimit && event.enduserAttendeeLimit > 1) {
            // fetch an updated version of the event to avoid removing newly added attendees
            const e = await session.api.calendar_events.getOne(event.id)
            
            return updateEvent(e.id, { 
              attendees: e.attendees.filter(e => e.id !== session.userInfo.id),
              cancelledGroupAttendees: (
                [...(e.cancelledGroupAttendees || []), { id: session.userInfo.id, at: new Date() }]
              )
            }, {
              replaceObjectFields: true
            })
            .then(() => setConfirmingCancel(false))
          }
          return updateEvent(event.id, { cancelledAt: new Date() }).then(() => setConfirmingCancel(false))
        }}
      />

      <Button variant="outlined" onClick={() => setConfirmingCancel(false)} sx={{ mt: 3 }}>
        No, don't cancel
      </Button>
    </Grid>
    </Grid>
    </ResponsiveModal>

    <Grid container sx={{ mx: DASHBOARD_CHILD_MARGIN, mt: DASHBOARD_CHILD_MARGIN }} direction="column" rowSpacing={2}>
      <Grid container alignItems="center" justifyContent="center" direction="column" spacing={2}>
        <Grid item sx={{ mt: 2 }}>
          <WithCircularBackground>
            <CalendarIcon />
          </WithCircularBackground>
        </Grid>

        <Grid item>
          <Typography style={{ fontWeight: 'bold', fontSize: 25 }}>
            {event.title} {
              (event.cancelledAt || event.cancelledGroupAttendees?.find(e => e.id === session.userInfo.id))
                ? `(Canceled)`
            : event.rescheduledAt
                ? "(Rescheduled)"
                : ''
            }
                        
            <LabeledIconButton Icon={DownloadIcon} label="Download ICS File" 
              size={20} offsetY={-1}
              onClick={() => 
                window.open(
                  `${session.host || 'https://api.tellescope.com'}/v1/calendar-events/download-ics-file?authToken=${session.authToken}&calendarEventId=${event.id}`,
                  '_blank'
                )
              }
            />
          </Typography>
        </Grid>

        <Divider flexItem sx={{ my: 3 }} />

        <EventDetails event={event} />

        <Grid item>
        <Grid container justifyContent={"center"}>
        {(event.videoIntegration === 'Zoom') 
          ? !event.videoURL
            ? (
              <Typography sx={{ fontWeight: 'bold', maxWidth: 400, fontSize: 18, px: 2, mt: 3, mb: 5 }}>
                Hold tight, your video call will start soon. 
                If it's past your start time, try refreshing this page every few minutes. 
                Your provider may be running late. 
              </Typography>
            ) : (
              <a href={event.videoURL} target="__blank" rel="noopener noreferrer"
                style={{ textDecoration: 'none'}}
              >
              <Button variant="contained" sx={{ height: 80, width: 200, my: 4 }}>
                <VirtualVisitIcon htmlColor="white" sx={{ mr: 1, }} /> Join Zoom Call
              </Button>
              </a>
            )
        : event.enableVideoCall 
          ? (
            <Button color="primary" variant="contained" 
              onClick={() => navigate(routes.video, { query: { calendarEventId: event.id }})}
              sx={{
                my: 4,
                borderRadius: 15,
                width: '100%',
                maxWidth: 400,
              }}
            >
              <VirtualVisitIcon htmlColor="white" sx={{ mr: 1, }} /> Join The Call
            </Button>
          )
          : null
        }
        </Grid>
      </Grid>
      </Grid>

      <Grid item>
      <Grid container direction="column" alignItems={"center"}>
        {event.bookingPageId && (!event.enduserAttendeeLimit || event.enduserAttendeeLimit === 1) &&
          <Grid item>
          <Button color="primary" variant="contained" 
            disabled={!!event.rescheduledAt}
            sx={{
              width: 200,
            }}
            onClick={() => setShowReschedule(true)}
          >
            Reschedule
          </Button>
          </Grid>
        }

        <Grid item>
        <LoadingButton variant="outlined"
          disabled={!!(event.cancelledAt || event.rescheduledAt || event.cancelledGroupAttendees?.find(e => e.id === session.userInfo.id))}
          submitText="Cancel Event"
          style={{
            width: 200,
            marginTop: 40,
          }}
          onClick={() => setConfirmingCancel(true)}
        />
        </Grid>
      </Grid>
      </Grid>

      <Grid item sx={{ mt: 1 }} />
    
      {tasks && <Grid item>{tasks}</Grid>}
      {responses && <Grid item>{responses}</Grid>}
      {content && <Grid item>{content}</Grid>}
      {files && <Grid item>{files}</Grid>}
    </Grid>
    </>
  )
}

export const EventsTable = ({ events, style } : { events: CalendarEvent[] } & Styled) => { 
  const session = useEnduserSession()
  const [, { doneLoading, loadMore }] = useCalendarEvents({ dontFetch: true })
  const navigate = useNavigateToPage()
  const sorted = useMemo(() => 
    sorted_records(events, { key: 'durationInMinutes' }), 
    [events]
  )

  return (
    <ScrollingList items={sorted} doneLoading={doneLoading} loadMore={loadMore}
      maxHeight={'50vh'} style={style}
      title="Your Events" emptyText="You have no scheduled events"
      Item={({ item: event }) => (
        <HoverPaper elevation={2} 
          onClick={() => navigate(routes.events, { id: event.id })}
          sx={{ padding: 1, marginBottom: 1 }}
        >
        <Grid container direction="column">
          <Grid item>
            <Typography>
              {event.title} 
              <strong>
                {(event.cancelledAt || event.cancelledGroupAttendees?.find(e => e.id === session.userInfo.id)) && " (Canceled)"}
                {event.rescheduledAt && " (Rescheduled)"}
              </strong>
            </Typography>
          </Grid>

          <Grid container alignItems="center" justifyContent="space-between">
            <Typography style={{ fontSize: 13 }}>
              {formatted_date(new Date(event.startTimeInMS))} 
            </Typography>
            
            <Typography style={{ fontSize: 13 }}>
              {event.durationInMinutes} min
            </Typography>
          </Grid>
        </Grid>
        </HoverPaper>
      )}
    />
  )
}

export const Events = ({ style } : { style?: React.CSSProperties }) => {
  const session = useResolvedSession()

  const [eventsLoading] = useCalendarEvents()
  const [, { filtered }] = useCalendarEventRSVPs({ loadFilter: { creator: session.userInfo.id } })
  
  const creatorRSVPsLoading = filtered(r => r.creator === session.userInfo.id)

  return (
    <LoadingLinear data={eventsLoading} render={events => (
      <Grid container direction="column" style={dashboardChildStyles}>
        <EventsTable style={style} events={
          events.filter(e => (
            !e.hiddenFromPortal 
            && (
              e.publicRead // only display public events when the client is RSVPed
                ? value_is_loaded(creatorRSVPsLoading)
                  ? creatorRSVPsLoading.value.find(rsvp => rsvp.eventId === e.id)
                  : false
                : true
            )
          ))
        } />
      </Grid> 
    )} />
  )
}